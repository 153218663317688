import H1 from '@/components/Redactie/H1/H1';
import Logo from '@/components/Redactie/Logo/Logo';
import Head from 'next/head';

export default function Home() {
    return (
        <>
            <Head>
                <title>{process.env.APP_NAME}</title>
            </Head>
            <div className="insets-0 from-primary absolute flex h-full w-full items-center justify-center bg-gradient-to-br to-pink-500">
                <div className="text-center">
                    <Logo className="h-24" />
                    <H1 className="mt-4 text-5xl">Podwalks</H1>
                </div>
            </div>
        </>
    );
}
