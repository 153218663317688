
const Logo = ({ className = '' }) => {
    return <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-12 inline-block transition-all ${className}`}
      viewBox="0 0 1000 391.08"
      version="1.1"
      style={{
        fill: 'currentColor',
      }}
    >
      <g transform="translate(-316.496 -153.734)">
        <path
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M1030.049 271.298c-34.05.013-64.035 17.27-81.732 43.523 1.96-10.644-2.784-21.825-12.684-27.55l-22.884-13.206c-12.573-7.243-28.657-2.941-35.916 9.632-7.243 12.573-2.942 28.657 9.631 35.916l22.885 13.19a26.167 26.167 0 0013.11 3.526c6.5 0 12.858-2.42 17.729-6.864a97.979 97.979 0 00-8.635 40.313v119.83h52.569V369.114c.05-25.336 20.575-46.544 45.927-46.591 25.351.05 45.879 21.255 45.926 46.59v8.493h52.57v-7.828c-.014-54.388-44.093-98.464-98.496-98.48m192.468 132.055c-24.292.013-43.95 19.674-43.981 43.982h.012c0 24.291 19.674 43.981 43.966 43.981s43.966-19.674 43.982-43.981c-.013-24.308-19.69-43.966-43.982-43.982m-402.729-5.71c-.05 25.352-20.575 48.553-45.942 48.6-25.352-.05-45.88-23.248-45.927-48.6v-85.258c3.163 10.96 13.253 19.01 25.24 19.01h42.796c14.518 0 26.285-11.782 26.285-26.285 0-14.518-11.767-26.284-26.285-26.284h-42.795c-11.988 0-22.078 8.034-25.241 19.01v-94.1l-52.569 26.949v165.63c.013 54.404 44.092 98.48 98.496 98.496 54.42-.013 98.496-44.092 98.511-98.496V384.66h-52.569v12.984zm402.73-39.347c24.291-.013 43.965-19.69 43.98-43.997-.012-24.308-19.689-43.966-43.98-43.982-24.293.013-43.95 19.69-43.982 43.982h.013c0 24.292 19.674 43.981 43.965 43.997M523.22 271.298c-34.034.013-64.035 17.27-81.732 43.523 1.977-10.644-2.783-21.825-12.683-27.55l-22.885-13.206c-12.573-7.243-28.64-2.941-35.9 9.632-7.259 12.573-2.941 28.657 9.632 35.916l22.884 13.19a26.124 26.124 0 0013.11 3.526c6.485 0 12.842-2.42 17.73-6.864-5.536 12.304-8.636 25.937-8.652 40.313v119.83h52.57V369.114c.05-25.336 20.575-46.544 45.926-46.591 25.352.05 45.88 21.255 45.927 46.59v120.495h52.569v-119.83c-.013-54.388-44.092-98.464-98.496-98.48"
        ></path>
      </g>
    </svg>;
}

export default Logo;