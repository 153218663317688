import {
    ChangeEventHandler,
    ReactElement,
    useEffect,
    useId,
    useState,
} from 'react';
import styles from './H1.module.sass';

interface H1Props {
    className?: string;
    value?: string;
    editable?: boolean;
    allowMultiLine?: boolean;
    children?: ReactElement | ReactElement[] | string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    placeholder?: string;
    maxLength?: number;
}

const H1: React.FC<H1Props> = ({
    className = '',
    value,
    editable = false,
    allowMultiLine = false,
    children,
    onChange,
    placeholder,
    maxLength,
    ...props
}: H1Props) => {
    const [hasValue, setHasValue] = useState(!!value);
    const inputId = useId();

    useEffect(() => {
        setHasValue(!!value);
    }, [value]);

    if (editable) {
        return (
            <div className={`relative grid grid-cols-1 ${className}`}>
                {!!placeholder && (
                    <label
                        className={`${styles.label} ${
                            hasValue && styles['label--filled']
                        }`}
                        htmlFor={inputId}
                    >
                        {placeholder}
                    </label>
                )}
                <input
                    id={inputId}
                    type="text"
                    className={`${styles.h1} ${
                        editable && styles['h1--editable']
                    } ${placeholder && hasValue && styles['h1--placeholder']} ${
                        hasValue && styles['h1--filled']
                    } text-3xl`}
                    onChange={(event) => {
                        onChange && onChange(event);
                        setHasValue(event.target.value.length > 0);
                    }}
                    value={value}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    {...props}
                />
            </div>
        );
    }

    return (
        <h1
            className={`${styles.h1} ${
                allowMultiLine ? styles['h1--multiline'] : ''
            } text-3xl ${className}`}
            {...props}
        >
            {children}
        </h1>
    );
};

export default H1;
